<template>
  <div>
    <div v-if="type === 'drop-file'" @dragenter.prevent="toggleActive" @dragleave.prevent="toggleActive"
         @dragover.prevent
         @drop.prevent="toggleActive" :class="{ 'active-dropzone': active, 'is-invalid': error,  'cursor-pointer': !disabled}"
         class="dropzone position-relative"
    >
      <div class="mb-1">
        <i class="display-4 text-muted ri-upload-cloud-2-fill"></i>
      </div>

      <h5 class="text-center">Drop files here or click to upload.</h5>
      <label for="dropzoneFile" class="bg-light text-dark stretched-link">Upload</label>
      <input :disabled="disabled" :accept="accept" :multiple="multiple" type="file" id="dropzoneFile" @change="onChange"
             class="dropzoneFile btn btn-primary"/>

    </div>
    <div v-else :class="{ 'is-invalid': error }">
      <!--      <input @change="onChange" ref="inputDropZone" :accept="accept" :multiple="multiple" type="file" class="form-control" :disabled="disabled">-->

      <div class="drop-container d-flex" :class="{'cursor-pointer': !disabled, 'highlight': highlight}" @click="chooseFile">
        <span id='button' class="px-1 d-flex align-items-center">ファイルを選択</span>
        <input :disabled="disabled" class="input-drop" :accept="accept" :multiple="multiple" @change="onChange" ref="inputDrop" type='file'>
        <span id='val' class="h-100 align-items-center d-flex"
              style="flex: 1">{{ state.nameFile }}</span>
      </div>
    </div>

    <div v-if="error" class="invalid-feedback text-center">
      {{ error }}
    </div>
  </div>
</template>


<script>
import {onMounted, reactive, ref, watch} from "vue";
import i18n from '@/i18n'
import {detectExtensionFile} from "@/state/helpers";

export default {
  name: "dropzone",
  props: {
    propNameFile: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "drop-file",
    },
    accept: {
      type: String,
      default: 'image/*'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    allowedExtensions: {
      type: Array,
      default: () => ['image/jpeg', 'image/png', 'image/jpg']
    },
    maxFileSize: {
      type: Number,
      default: 10
    },
    showErrorInside: {
      type: Boolean,
      default: true
    },
    highlight: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}) {
    const active = ref(false);
    const state = reactive({
      nameFile: '選択されていません'
    })

    const inputDrop = ref(null);
    const refreshInput = () => {
      inputDrop.value.value = '';
    }

    onMounted(() => {
      state.nameFile = props.propNameFile !== '' ? props.propNameFile : '選択されていません';
    })

    watch(() => props.propNameFile, (value) => {
      state.nameFile = value
    })

    const toggleActive = async (e) => {
      if (props.disabled) {
        return;
      }
      active.value = !active.value;
      const file = e.dataTransfer.files[0];
      const check = await validateFile(file);
      if (check) {
        emit('selectedFile', file);
      }
    };

    const error = ref('');

    watch(() => props.propNameFile, (value) => {
      state.nameFile = value !== '' ? value : '選択されていません';
    })

    const validateFile = async (file) => {
      if (file === null || typeof file === 'undefined') return;
      const type = await detectExtensionFile(file);
      const maxFileSize = type.type === 'video' ? 500 : 10;
      const {allowedExtensions} = props;
      if (allowedExtensions.length > 0 && !allowedExtensions.includes(file.type)) {
        const arrayTemp = props.allowedExtensions.map(extend => {
          const splitText = extend.split('/');
          return splitText.length ? splitText[splitText.length - 1] : extend
        })
        let errorMessage = i18n.global.t('msg.only_accept_file', {acceptFiles: arrayTemp.join(', ')})
        if (props.showErrorInside) {
          error.value = errorMessage
        } else {
          emit('errorUpload', errorMessage)
        }

        return false;
      }
      if (file.size / 1024 / 1024 > maxFileSize) {
        let errorMessage = i18n.global.t('t-err-upload-max', {value: maxFileSize});
        if (props.showErrorInside) {
          error.value = errorMessage
        } else {
          emit('errorUpload', errorMessage)
        }
        return false;
      }

      if (!props.showErrorInside) {
        emit('errorUpload', '')
        }
      error.value = '';
      return true;
    }

    const onChange = async (e) => {
      if (props.disabled) {
        return;
      }
      const files = e.target.files || e.dataTransfer.files;
      const file = files[0] || null;

      const check = await validateFile(file);
      if (check) {
        state.nameFile = file.name;
        emit('selectedFile', file);
      }
    }

    return {active, error, toggleActive, onChange, state, refreshInput, inputDrop};
  },
  methods: {
    chooseFile() {
      this.$refs.inputDrop.click();
    }
  }
};
</script>
<style lang="scss" scoped>
.input-drop {
  height: 38px;
  opacity: 0;
  display: none;
}

.drop-container {
  border-radius: 4px;
  height: 38px;
  background-color: white;
  //box-shadow: 1px 2px 3px #ededed;
  position: relative;
  border: 1px solid #d8d8d8;
}

#val {
  height: 25px;
  font-size: 13px;
  line-height: 38px;
  text-indent: 10px;
  pointer-events: none;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

#button {
  width: 107px;
  display: block;
  background-color: #f0f2f6;
  color: black;
  font-size: 13px;
  line-height: 25px;
  text-align: center;
  -webkit-transition: 500ms all;
  -moz-transition: 500ms all;
  transition: 500ms all;
}
.highlight {
  border-color: #edc86f;
}
</style>
