<template>
  <Layout>
    <PageHeader :title="state.title" :items="state.items" />
    <div class="rounded-3">
      <div class="row">
        <div v-if="!disabled" class="col-md-3">
          <div class="card">
            <div class="card-header card-header-radius">
              通知登録
            </div>
            <div class="d-flex flex-column p-2 ml-10">
              <label for="titleNotify" class="label mt-3">
                {{ $t('pushNotification.title') }} <span class="text-danger">*</span>
                <input v-model="state.formData.title" type="text" maxlength="255" id="titleNotify"
                  placeholder="タイトルを入力してください" class="form-control" />
                <div v-if="v$.title.required.$invalid && invalid" class="error-text-show">
                  {{ $t('field_required', { field: $t('pushNotification.title') }) }}
                </div>
              </label>

              <label for="sendToNotify" class="label mt-3 mb-0" v-if="currentUser.role === ROLES.ADMIN">
                {{ $t('pushNotification.deliveryDestination') }}<span class="text-danger">*</span>
                <Multiselect v-model="state.formData.sendTo" :options="state.facilitiesOption"
                  class="multiselect-input" :track-by="'name'" :label="'name'" :multiple="false" :searchable="true"
                  style="padding: 0;" :can-clear="false" :can-deselect="false" placeholder="配信先を選択してください">
                  <template #noresults>
                    <div class="d-flex justify-content-center m-2">{{ $t('t-no-data') }}</div>
                  </template>
                  <template #nooptions>
                    <div class="d-flex justify-content-center m-2">{{ $t('t-no-data') }}</div>
                  </template>
                </Multiselect>
                <div v-if="v$.sendTo.required.$invalid && invalid" class="error-text-show">
                  {{ $t('field_select_required', { field: $t('pushNotification.deliveryDestination') }) }}
                </div>
              </label>

              <label for="descriptionNotify" class="label mt-3">
                {{ $t('pushNotification.explanation') }}
                <textarea placeholder="説明を入力してください" rows="3" v-model="state.formData.description" class="form-control"
                  :maxlength="1000" />
              </label>

              <div class="mt-3">
                <label for="name" class="form-label label mb-0">画像</label>
                <DropZone :max-file-size="500" :show-error-inside="false"
                  :allowedExtensions="['image/jpeg', 'image/png', 'image/jpg']" type="file-input" ref="inputDropInput"
                  @selectedFile="selectedFile" @errorUpload="handleErrorUpload" :prop-name-file="state.fileNameShow" />
                <DropZone :max-file-size="500" :show-error-inside="false"
                  :allowedExtensions="['image/jpeg', 'image/png', 'image/jpg']" v-if="!fileNotify" :type="'drop-file'"
                  @selectedFile="selectedFile" @errorUpload="handleErrorUpload" :prop-name-file="state.fileNameShow" />
                <img :src="fileNotify" v-if="fileNotify" alt="facilityForm-image" class="w-100">
                <div v-if="messageFileUpload !== ''" class="error-text-show">
                  {{ messageFileUpload }}
                </div>
                <div class="error-text-show" v-if="state.errorMessageUpload">
                  {{ state.errorMessageUpload }}
                </div>
              </div>
              <div class="error-text-show">
                画像のサイズは400x600 pxになります。<br /> 画像設定しない場合は、デフフォルトの画像で利用します。
              </div>

              <label for="showDate" class="label mt-3 mb-0 d-flex align-items-center cursor-pointer">
                時間設定
                <div class="form-check form-switch p-0">
                  <input v-model="state.showDate" class="form-check-input checkbox ml-2 p-0" type="checkbox" role="switch"
                    :disabled="disabled" id="showDate">
                </div>
              </label>

              <label for="publicTime" class="label mt-3 mb-0 text-nowrap d-flex align-items-center" v-if="state.showDate">
                配信日時 <span class="text-danger">*</span>
                <div class="ml-2 form-control d-flex">
                  <flat-pickr placeholder="時間設定を選択してください" id="publicTime" v-model="state.formData.publicTime"
                    :config="state.dateTimeConfig" class="form-control" @onOpen="() => resetError('publicTime')" />
                  <div class="d-flex align-items-center date-picker">
                    <i class="bx bx-calendar-alt" style="font-size: 20px"></i>
                  </div>
                </div>
              </label>

              <div class="error-text-show" v-if="state.errorDate">
                {{ state.errorDate }}
              </div>

              <div class="mt-3">
                <b-button :disabled="((currentFacilityId === '' || currentFacilityId === null) && currentUser.role !== ROLES.ADMIN) || state.isLoading" variant="primary"
                  class="waves-effect waves-light w-100 d-flex justify-content-center" style="column-gap: 10px"
                  @click="popupConfirmHandle">
                  <Loading v-if="state.isLoading" />
                  配信
                </b-button>
              </div>
            </div>
          </div>
        </div>

        <div :class="disabled ? 'col-md-12' : 'col-md-9'">
          <div class="card pb-3">
            <div class="card-header card-header-radius">
              通知履歴
            </div>
            <TableCommon ref="tablePushNotification" :columns="state.columns" :endpoint="state.endpointTable" :data-table="state.dataTable"
              @onDelete="handleDeleteNotify" :query="state.query" />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>

import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import { computed, onBeforeMount, onMounted, reactive, ref, watch } from "vue";
import i18n from "@/i18n";
import { facilityService, pushNotificationService } from "@/services";
import Multiselect from "@vueform/multiselect";
import DropZone from "@/components/widgets/dropZone";
import { detectExtensionFile } from "@/state/helpers";
import { clientOSS } from "@/helpers/axios/formDataRequest";
import TableCommon from "@/components/common/TableCommon.vue";
import flatPickr from "vue-flatpickr-component";
import Loading from "@/components/Loading.vue";
import { ROLES, RULE_NOTIFICATION } from "@/constants";
import useVuelidate from "@vuelidate/core";
import { API_PATH } from "@/constants";
import store from "@/state/store";
import { get } from "lodash";
import * as moment from "moment/moment";
import Swal from "sweetalert2";

export default {
  name: "index",
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DropZone,
    TableCommon,
    flatPickr,
    Loading
  },
  setup() {
    const currentUser = computed(() => store.getters[`auth/currentUser`]);
    const disabled = computed(() => (currentUser.value && currentUser.value.role === ROLES.FACILITY_ACCOUNT) || !currentUser.value);
    console.log(disabled)
    const inputDropInput = ref(null);

    const getSecondAsDate = () => {
      return moment(moment().format('YYYY-MM-DD HH:mm')).add(6, 'minutes').subtract(1, 'second').toDate()
    }

    const state = reactive({
      fileNameShow: '',
      errorMessageUpload: '',
      errorDate: '',
      formData: {
        title: '',
        sendTo: '',
        description: '',
        url: '',
        publicTime: moment().add(5, 'minutes').toDate(),
        facilityId: null
      },
      title: i18n.global.t('pushNotification.notification'),
      items: [
        {
          text: 'ホーム',
          href: "/",
        },
        {
          text: i18n.global.t('pushNotification.notification'),
          active: true,
        },
      ],
      facilitiesOption: [],
      columns: [
        {
          sortable: false,
          label: 'No',
          type: 'stt'
        },
        {
          key: 'notifications.title',
          sortable: true,
          dynamicText: (row) => `${get(row, 'title', '')}`,
          label: i18n.global.t('pushNotification.title'),
          maxWidth: 200
        },
        {
          key: 'facilities.name',
          dynamicText: (row) => `${row.sendTo.toLowerCase() === 'all' ? '全て' : get(row, 'facility.name', '')}`,
          sortable: true,
          label: i18n.global.t('pushNotification.deliveryDestination'),
          maxWidth: 200
        },
        {
          key: 'notifications.description',
          dynamicText: (row) => `${get(row, 'description', '')}`,
          sortable: false,
          label: i18n.global.t('pushNotification.explanation'),
          maxWidth: 200
        },
        {
          key: 'notifications.status',
          dynamicText: (row) => `${row['status'] === 'PUBLICED' ? i18n.global.t('pushNotification.delivered') : i18n.global.t('pushNotification.delivery_schedule')}`,
          sortable: false,
          label: i18n.global.t('publicAPI.status')
        },
        {
          key: 'notifications.publicTime',
          type: "datetime",
          dynamicText: (row) => `${formatDate(row)}`,
          sortable: true,
          label: i18n.global.t('pushNotification.deliveryDateTime')
        },
        {
          key: 'action',
          options: ['delete'],
          conditionDelete: (row) => `${row['status'] !== 'PUBLICED' && !disabled.value}`,
          type: 'action',
          sortable: false,
          label: '',
          isDisabled: () => disabled.value
        }
      ],
      showDate: true,
      dateTimeConfig: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        minuteIncrement: 30,
        minDate: new Date(),
        time_24hr: true
      },
      isLoading: false,
      query: {
        sortBy: 'notifications.createdAt'
      },
      endpointTable: '',
      dataTable: []
    });
    const messageFileUpload = ref('');
    const fileUpload = ref(null);
    const fileNotify = ref('');
    const invalid = ref(false);
    const tablePushNotification = ref(null);
    const currentFacilityId = computed(() => store.getters['settings/getFacilityId']);

    const form = computed(() => state.formData)
    const v$ = useVuelidate(RULE_NOTIFICATION, form);

    const getOptionsFacility = async () => {
      try {
        const res = await facilityService.getOptionsAllFacility({ page: 1, pageSize: 500 });
        state.facilitiesOption = res.data;
        state.facilitiesOption.unshift({ value: 'all', name: "全て" })
      } catch (e) {
        console.log(e)
      }
    }

    const uploadToOss = async (file) => {
      return await clientOSS.put(`/ads/${file.name}`, file);
    }

    const resetError = (fieldName) => {
      if(fieldName === 'publicTime') {
        state.errorDate = ''
        return;
      }
      v$.value[fieldName].$reset();
    }

    const selectedFile = async (file) => {
      const type = detectExtensionFile(file);
      messageFileUpload.value = '';
      if (!type) {
        state.formData.url = '';
        fileUpload.value = '';
        fileNotify.value = '';
        messageFileUpload.value = 'ファイルが無効です';

        return;
      }
      let uploadOss = {}
      try {
        uploadOss = await uploadToOss(file);
        state.fileNameShow = file.name
      } catch (e) {
        console.log(e)
        return;
      }

      state.formData.url = uploadOss.name;
      fileUpload.value = file;
      fileNotify.value = URL.createObjectURL(file);
    }

    onMounted(() => {
      if (!(currentUser.value.role !== ROLES.ADMIN && (currentFacilityId.value === '' || currentFacilityId.value === null))) {
        state.endpointTable = API_PATH.NOTIFICATION
      }
    })

    watch(() => state.showDate, (show) => {
      if (!show) {
        state.formData.publicTime = null;
        state.errorDate = ''
      } else {
        state.formData.publicTime = moment().add(5, 'minutes').toDate()
      }
    })

    watch(() => currentFacilityId.value, (value) => {
      if (value) {
        state.formData.facilityId = value;
        state.query['facilityId'] = value || '';
        state.formData.sendTo = value;
      }
    })

    const validateDate = () => {
      let validate = true;
      if (state.showDate) {
        validate = !!state.formData.publicTime
      }

      state.errorDate = !validate ? i18n.global.t('field_select_required', { field: '配信日時' }) : ''

      const now = moment()
      if(state.showDate && state.formData.publicTime && now.isAfter(moment(state.formData.publicTime))) {
        state.errorDate = i18n.global.t('msg.MSG_078')
        return false
      }

      const formatNow = moment(moment().format('YYYY-MM-DD HH:mm'))
      if(state.showDate && state.formData.publicTime && moment(moment(state.formData.publicTime).format('YYYY-MM-DD HH:mm')).diff(formatNow, 'minutes') < 5) {
        state.errorDate = i18n.global.t('msg.MSG_MINUTE', { minute: 5})
        return false
      }

      return validate;
    }

    const popupConfirmHandle = async() => {
      v$.value.$touch();
      invalid.value = v$.value.$invalid
      if(v$.value.$invalid) return;

      const { value: accept} = await Swal.fire({
        title: `<h2 class='swal2-title' id='swal2-title' style='display: block; font-size: 20px !important;'>${i18n.global.t('pushNotification.confirm')}</h2>`,
        html: `<h2 class='font-normal' style='font-size: 14px;'>${i18n.global.t('pushNotification.description')}</h2>`,
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: i18n.global.t('yes'),
        cancelButtonText: i18n.global.t('cancel')

      })

      if(accept) {
        handleSubmit()
      }
    }

    const handleSubmit = async () => {
      const isPassValidatePublicTime = validateDate()
      v$.value.$touch();
      invalid.value = v$.value.$invalid;
      if (!invalid.value && isPassValidatePublicTime) {
        try {
          state.isLoading = true;
          let facilityId = state.formData.sendTo === 'all' ? null : state.formData.sendTo;
          const type = state.formData.sendTo === 'all' ? 'ALL' : 'FACILITY'
          const payload = {
            ...state.formData,
            facilityId: facilityId,
            sendTo: type,
            publicTime: moment(state.formData.publicTime).utc().toISOString()
          };

          await pushNotificationService.createPushNotification(payload);
          v$.value.$reset()
          state.formData = {
            title: '',
            sendTo: currentUser.value.role !== ROLES.ADMIN ? facilityId : '',
            description: '',
            url: '',
            publicTime: moment().add(5, 'minutes').toDate(),
            facilityId
          }
          state.showDate = true;
          fileNotify.value = '';
          fileUpload.value = null;
          state.fileNameShow = '';
          inputDropInput.value.refreshInput()
          tablePushNotification.value.refreshTable();
          store.commit('settings/SET_POPUP', {
            show: true,
            type: 'success',
            message: i18n.global.t('msg.saved')
          });
        } catch (e) {
          store.commit('settings/SET_POPUP', {
            show: true,
            type: 'error',
            message: e.message
          });
        } finally {
          state.isLoading = false;
        }
      }
    }

    const formatDate = (date) => {
      const time = date.publicTime ? date.publicTime : date.createdAt

      return moment(time).format("YYYY-MM-DD HH:mm:ss");
    }

    const handleDeleteNotify = async (value) => {
      const { id, title } = value;

      Swal.fire({
        title: `<h2 class='swal2-title' id='swal2-title' style='display: block; font-size: 20px !important;'>${i18n.global.t('deleted_message', { message: title || '' })}</h2>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: i18n.global.t('yes'),
        cancelButtonText: i18n.global.t('cancel'),
      })
          .then(async (result) => {
            if (result.value) {
              try {
                // handle delete
                await pushNotificationService.deletePushNotification(id);
              } catch (e) {
                const message = get(e, 'response.data.message') || e.message || '';
                store.commit('settings/SET_POPUP', {
                  show: true,
                  type: 'error',
                  message: message
                });
              } finally {
                tablePushNotification.value.refreshTable();
              }
            }
          });
    }

    onBeforeMount(() => {
      if (currentUser.value.role !== ROLES.ADMIN) {
        state.query['facilityId'] = currentFacilityId.value || '';
      }
    })

    onMounted(() => {
      if (currentUser.value.role === ROLES.ADMIN) {
        getOptionsFacility()
      } else {
        state.formData.sendTo = currentFacilityId.value;
        state.formData.facilityId = currentFacilityId.value;
      }
    })

    const handleErrorUpload = (value) => {
      state.errorMessageUpload = value
    }

    return {
      v$,
      state,
      fileUpload,
      fileNotify,
      messageFileUpload,
      invalid,
      tablePushNotification,
      currentFacilityId,
      currentUser,
      API_PATH,
      ROLES,
      formatDate,
      handleSubmit,
      selectedFile,
      handleDeleteNotify,
      handleErrorUpload,
      disabled,
      inputDropInput,
      popupConfirmHandle,
      resetError
    }
  }
}
</script>

<style scoped lang="scss">
.multiselect-input ::v-deep {
  .multiselect-wrapper {
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    min-height: calc(var(--ms-border-width, 1px) * 2 + var(--ms-font-size, 1rem) * var(--ms-line-height, 1.375) + var(--ms-py, .5rem) * 2 - 2px);
    outline: none;
    position: relative;
    width: 100%;
  }
}

.checkbox {
  font-size: 20px;
}

.flatpickr-input {
  border: none !important;
  padding: 0;
}

.error-text-show {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ed5e5e;
}

.card-header-radius {
  border-radius: 10px 10px 0 0;
  font-size: 16px;
  color: var(--vz-heading-color);
}
</style>
